@pkg: layers;

@border-color: #e8e8e8;
@border-style: 1px solid @border-color;
@border-radius: 6px;

.@{pkg}-list {
  width: 300px;
  border: @border-style;
  border-radius: 0px 0px @border-radius @border-radius;
  border-top: 0px;
  padding: 10px 0px;
}

.@{pkg}-header {
  padding: 20px;
  font-size: 1.1rem;
  border-radius: @border-radius @border-radius 0px 0px;
  border: @border-style;
  display: flex;
  flex-direction: row;
  &-title {
    flex-grow: 1;
  }
}

.@{pkg}-item {
  display: flex;
  height: 50px;
  padding: 8px 16px;
  align-items: baseline;

  &.selected {
    background: #eee;
  }

  &-show {
    margin-right: 16px;
    &.base {
      visibility: hidden;
    }
  }

  &-name {
    flex-grow: 1;
  }

  &-control {
    cursor: pointer;
    color: #888;

    &-edit {
      font-size: 11px;
    }

    &:hover {
      color: #333;
    }
  }
}

@primary-color: #1DA57A;