@pkg: main;

.@{pkg}-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .@{pkg}-content {
    display: flex;
    flex-direction: row;

    & > div {
      margin-right: 20px;
    }
  }

  & .@{pkg}-footer {
    margin-top: 20px;
  }
}

@primary-color: #1DA57A;