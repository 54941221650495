@pkg: resize-anchor;
@resize-anchor-size: 15px;
@resize-anchor-position: -7px;

.@{pkg} {
  position: absolute;
  width: @resize-anchor-size;
  height: @resize-anchor-size;
  background-color: white;
  border: 1px solid #888;
  border-radius: 100%;
  user-select: none;
  z-index: 999;

  &-top-left {
    top: @resize-anchor-position;
    left: @resize-anchor-position;
    cursor: nw-resize;
  }

  &-top-right {
    top: @resize-anchor-position;
    right: @resize-anchor-position;
    cursor: ne-resize;
  }

  &-bottom-left {
    bottom: @resize-anchor-position;
    left: @resize-anchor-position;
    cursor: ne-resize;
  }

  &-bottom-right {
    bottom: @resize-anchor-position;
    right: @resize-anchor-position;
    cursor: nw-resize;
  }
}

@primary-color: #1DA57A;