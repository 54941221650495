@pkg: preview;

.@{pkg} {
  position: relative;
  width: 728px;
  height: 728px;
  overflow: hidden;

  & .preview-layer {
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    .preview-content {
      overflow: hidden;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.selected {
      cursor: grab;

      &:active,
      & .preview-content:active {
        cursor: grabbing;
      }
    }
  }
}

@primary-color: #1DA57A;